<footer class="mt-4 d-flex flex-column m-auto">
  <div class="text-lwt-muted text-center">© 2022 Tools4Profit</div>
  <div class="text-lwt-muted fs-8 mx-4 text-justify mt-3">
    The calculators and tools in this application are provided for your information and to illustrate scenarios. The
    results should not be taken as a substitute for professional advice. All reasonable care has been taken in preparing
    and designing the calculators and tools; however, Tools4Profit provides no warranties and makes no representation
    that the information provided by the calculators and tools is appropriate for your particular circumstances or
    indicates you should follow a particular course of action. Tools4Profit nor any of its employees or owners shall be
    held liable or responsible for any errors or omissions in this application or for any damage you may suffer as a
    result of the use of or reliance on any of the information or advice in this application or for failing to seek
    competent financial advice from a professional who is familiar with your situation.
  </div>
</footer>
