// action_text
import "trix";
import "@rails/actiontext";

// Import our custom CSS
import "../stylesheets/styles.scss";

// Import all of Bootstrap's JS
// import * as bootstrap from 'bootstrap'
// import 'bootstrap/js/dist/alert';
import "bootstrap/js/dist/button";
// import 'bootstrap/js/dist/carousel';
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import Layout from "../layout/Layout.svelte";

import { createInertiaApp } from "@inertiajs/svelte";
// import { InertiaProgress } from "@inertiajs/progress";

// import axios from 'axios'
// const csrfToken = document.querySelector('meta[name=csrf-token]').content
// axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

const pages = import.meta.glob("../pages/**/*.svelte");

// InertiaProgress.init();

createInertiaApp({
  progress: {
    color: "#e12490"
  },
  resolve: async name => {
    const page = await pages[`../pages/${name}.svelte`]();
    return Object.assign({ layout: Layout }, page);
  },
  setup({ el, App, props }) {
    new App({ target: el, props });
  }
});
