<script>
  export let size = "fs-6";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 128 512"
  fill="currentColor"
  class="nav-icon {size}"
  focusable="false"
>
  <path
    d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z"
  />
</svg>
