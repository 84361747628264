<script>
  import { page, inertia } from "@inertiajs/svelte";
  import { sideNav } from "@/stores/store";
  import { onMount, onDestroy } from "svelte";
  import Tooltip from "bootstrap/js/dist/tooltip";
  import MenuBars from "../icons/MenuBars.svelte";
  import MenuDots from "../icons/MenuDots.svelte";
  import Profile from "../icons/Profile.svelte";
  import Logout from "../icons/Logout.svelte";
  import ChevronCircle from "../icons/ChevronCircle.svelte";

  // import Business from "../icons/Business.svelte";
  $: extViewing = $page.props.businessInfo ? $page.props.businessInfo.extViewing : false;
  let role = "Client";
  let url = "";
  $: {
    if ($page.props.auth) {
      switch ($page.props.auth.user.role) {
        case "developer":
          role = "Developer";
          break;
        case "admin":
          role = "Admin";
          url = "admin";
          break;
        case "coach":
        case "master_coach":
          role = "Coach";
          url = "coach";
          break;
        default:
          role = "Client";
          url = "";
      }
    }
  }

  //  Tooltip setup and settings
  let tooltipTriggerList = [];
  let tooltipList = [];
  const show = { show: 1500, hide: 0 };

  function setupToolTips() {
    tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl, { trigger: "hover" }));
  }

  function hideToolTips() {
    tooltipList.forEach(tooltipTriggerEl => tooltipTriggerEl.hide());
  }

  onMount(() => {
    setupToolTips();
  });

  onDestroy(() => {
    hideToolTips();
  });
</script>

<header class="container">
  <nav class="mb-3 d-flex align-items-center">
    <!-- TODO: add menu hover -->
    <div
      class="menu-btn bg-white shadow text-lwt-muted btn"
      on:click={sideNav.toggle}
      on:keyup={e => {
        if (e.code === "Space") {
          sideNav.toggle;
        }
      }}
      role="button"
      tabindex="0"
      aria-label="Expand / collapse menu"
    >
      {#if $sideNav}
        <MenuDots />
      {:else}
        <MenuBars />
      {/if}
    </div>
    <div class="me-auto text-lwt-muted ms-3 d-block d-md-flex align-items-center">
      {#if $page.props.businessInfo}
        <h4 class="mb-0 fw-light">{$page.props.businessInfo.business.name}</h4>
      {/if}
      {#if extViewing}
        <span class="badge bg-danger ms-0 ms-md-3">{`Viewing as ${role}`}</span>
      {/if}
    </div>
    <div class="d-flex align-items-center">
      {#if $page.props.auth}
        {#if role === "Admin"}
          <div class="d-none d-lg-flex">
            <div class="text-lwt-muted me-1">
              <div
                class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour"
                use:inertia={{ href: `/${url}/coaches`, method: "get" }}
              >
                Coaches
              </div>
            </div>

            <div class="text-lwt-muted me-1">
              <div
                class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour"
                use:inertia={{ href: `/${url}/users`, method: "get" }}
              >
                Users
              </div>
            </div>

            <div class="text-lwt-muted me-1">
              <div
                class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour"
                use:inertia={{ href: `/${url}/businesses`, method: "get" }}
              >
                Businesses
              </div>
            </div>
            <div class="vr me-4" />
          </div>

          <div class="dropdown d-block d-lg-none">
            <div
              class="pointer menu-icon-colour shadow-sm mobile-menu"
              id="dropdownAdmin"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ChevronCircle size="fs-2" />
            </div>
            <ul class="dropdown-menu dropdown-menu-end text-lwt-muted" aria-labelledby="dropdownAdmin">
              <li class="ps-3">
                {`${$page.props.auth.user.first_name} ${$page.props.auth.user.last_name}`}
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href={`/${url}/coaches`} use:inertia={{ method: "get" }}
                  >Coaches</a
                >
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href={`/${url}/users`} use:inertia={{ method: "get" }}>Users</a>
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href={`/${url}/businesses`} use:inertia={{ method: "get" }}
                  >Businesses</a
                >
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/profile" use:inertia={{ method: "get" }}>Profile</a>
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/sign_out" use:inertia={{ method: "delete" }}>Logout</a>
              </li>
            </ul>
          </div>
        {:else if role === "Coach"}
          <div class="d-none d-lg-flex">
            <div class="text-lwt-muted me-3">
              <div
                class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour"
                use:inertia={{ href: `/${url}/users`, method: "get" }}
              >
                Users
              </div>
            </div>

            <div class="text-lwt-muted me-3">
              <div
                class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour"
                use:inertia={{ href: `/${url}/businesses`, method: "get" }}
              >
                Businesses
              </div>
            </div>
            <div class="vr me-4" />
          </div>

          <div class="dropdown d-block d-lg-none">
            <div
              class="pointer menu-icon-colour mobile-menu"
              id="dropdownCoach"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ChevronCircle size="fs-2" />
            </div>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownCoach">
              <li class="ps-3 text-lwt-muted">
                {`${$page.props.auth.user.first_name} ${$page.props.auth.user.last_name}`}
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href={`/${url}/users`} use:inertia={{ method: "get" }}>Users</a>
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href={`/${url}/businesses`} use:inertia={{ method: "get" }}
                  >Businesses</a
                >
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/profile" use:inertia={{ method: "get" }}>Profile</a>
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/sign_out" use:inertia={{ method: "delete" }}>Logout</a>
              </li>
            </ul>
          </div>
        {:else if role === "Client"}
          <div class="dropdown d-block d-lg-none">
            <div
              class="pointer menu-icon-colour mobile-menu"
              id="dropdownClient"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ChevronCircle size="fs-2" />
            </div>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownClient">
              <li class="ps-3 text-lwt-muted">
                {`${$page.props.auth.user.first_name} ${$page.props.auth.user.last_name}`}
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/profile" use:inertia={{ method: "get" }}>Profile</a>
              </li>
              <li>
                <a class="dropdown-item text-lwt-muted" href="/sign_out" use:inertia={{ method: "delete" }}>Logout</a>
              </li>
            </ul>
          </div>
        {/if}

        <div class="d-none d-lg-flex align-items-center">
          {#if $page.props.auth.user.first_name !== null && $page.props.auth.user.last_name !== null}
            <div class="text-lwt-muted me-3 d-none d-md-block">
              <span
                class="help-tip"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                data-bs-title="Logged in User"
                data-bs-delay={JSON.stringify(show)}
              >
                {`${$page.props.auth.user.first_name} ${$page.props.auth.user.last_name}`}
              </span>
            </div>
          {/if}
          <div
            class="px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour help-tip"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Profile"
            data-bs-delay={JSON.stringify(show)}
            use:inertia={{ href: "/profile", method: "get" }}
          >
            <Profile size="fs-4" />
          </div>

          <div
            class=" px-3 py-2 menu-icon pointer border-radius-4 transition-500 btn menu-icon-colour help-tip"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-title="Log out"
            data-bs-delay={JSON.stringify(show)}
            data-bs-custom-class="grey-tooltip"
            use:inertia={{ href: "/sign_out", method: "delete" }}
          >
            <Logout size="fs-4" />
          </div>
        </div>
      {/if}
    </div>
  </nav>
</header>

<style>
  .menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
  }

  .menu-btn:hover {
    background-color: var(--lwt-hover-colour) !important;
  }

  .menu-icon-colour {
    color: rgb(123, 128, 154);
  }

  .menu-icon:hover {
    background-color: var(--lwt-hover-colour) !important;
  }

  .mobile-menu {
    border-radius: 50%;
    background-color: var(--lwt-bg-color) !important;
  }
</style>
