<script>
  export let size = "fs-6";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  class="nav-icon {size}"
  fill="currentColor"
  focusable="false"
>
  <path fill="none" d="M0 0h24v24H0z" />
  <path
    d="M22 9.999V20a1 1 0 0 1-1 1h-8V9.999h9zm-11 6V21H3a1 1 0 0 1-1-1v-4.001h9zM11 3v10.999H2V4a1 1 0 0 1 1-1h8zm10 0a1 1 0 0 1 1 1v3.999h-9V3h8z"
  />
</svg>
