import { writable } from "svelte/store";

function createSideNav() {
  const { subscribe, update } = writable(true);

  return {
    subscribe,
    toggle: () => {
      update(show => (show = !show));
    }
  };
}

export const sideNav = createSideNav();
