<script>
  import { page, inertia } from "@inertiajs/svelte";

  export const { routes } = $page.props;
  import { sideNav } from "@/stores/store";

  let currentPositionAccess;
  let scenarioTestingAccess;
  let futurePlanningAccess;
  let snapshotsAccess;

  $: currentPositionAccess = $page.props.access ? $page.props.access.currentPositionAccess : false;
  $: scenarioTestingAccess = $page.props.access ? $page.props.access.scenarioTestingAccess : false;
  $: futurePlanningAccess = $page.props.access ? $page.props.access.futurePlanningAccess : false;
  $: snapshotsAccess = $page.props.access ? $page.props.access.snapshotsAccess : false;

  // icons
  import Business from "../icons/Business.svelte";
  import Dashboard from "../icons/Dashboard.svelte";
  import Employees from "../icons/Employees.svelte";
  import Lock from "../icons/Lock.svelte";
  import Wages from "../icons/Wages.svelte";
  import Expenses from "../icons/Expenses.svelte";
  import ChargeOut from "../icons/ChargeOut.svelte";
  import Hours from "../icons/Hours.svelte";
  import Kpi from "../icons/Kpi.svelte";
  import PiggyBank from "../icons/PiggyBank.svelte";
  import Comparison from "../icons/Comparison.svelte";
  import Breakeven from "../icons/Breakeven.svelte";
  import Future from "../icons/Future.svelte";
  import Goals from "../icons/Goals.svelte";
  import Projections from "../icons/Projections.svelte";
  import Scenario from "../icons/Scenario.svelte";
  import CashGap from "../icons/CashGap.svelte";
  import Tools from "../icons/Tools.svelte";
  import ChevronDown from "../icons/ChevronDown.svelte";
  import MoneyCheck from "@/icons/MoneyCheck.svelte";
  import MagnifyingGlassDollar from "@/icons/MagnifyingGlassDollar.svelte";
  import Camera from "~/icons/Camera.svelte";
  import Clipboard from "~/icons/Clipboard.svelte";
</script>

<aside>
  <!-- mobile nav -->
  <nav
    class="flex d-lg-none flex-column flex-shrink-0 p-3 text-bg-dark position-fixed top-0 bottom-0 overflow-x-hidden
    overflow-y-auto transition-500 w-260 z-index-5 {!$sideNav ? 'translate-x-0' : 'translate-x-neg-full'}"
  >
    <a href="/" class="d-flex align-items-center mb-1 me-md-auto text-white text-decoration-none">
      <span class="fs-5 ms-3">Tools4Profit</span>
    </a>
    <hr />
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item mb-2">
        <a
          href="/"
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === "/"}
          use:inertia={{ method: "get" }}
          on:click={sideNav.toggle}
        >
          <Dashboard size="fs-5" />
          <span class="ms-4 fs-7">Dashboard</span>
        </a>
      </li>
      <li class="nav-item mb-2">
        <a
          href={routes.business}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.business}
          use:inertia={{ method: "get" }}
          on:click={sideNav.toggle}
        >
          <Business size="fs-5" />
          <span class="ms-4 fs-7">Business</span>
        </a>
      </li>
      <li class="nav-item mb-1">
        <a
          href={routes.employees}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.employees}
          use:inertia={{ method: "get" }}
          on:click={sideNav.toggle}
        >
          <Employees size="fs-5" />
          <span class="ms-4 fs-7">Employees</span>
        </a>
      </li>
      <li class="nav-item mb-1">
        <a
          href={routes.notes}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.notes}
          use:inertia={{ method: "get" }}
          on:click={sideNav.toggle}
        >
          <Clipboard size="fs-5" />
          <span class="ms-4 fs-7">Notes</span>
        </a>
        <hr />
      </li>
      <li class="nav-item mb-1">
        <a
          href={routes.snapshots}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url.includes(routes.snapshots)}
          use:inertia={{ method: "get" }}
        >
          <Camera size="fs-4" />
          <span class="ms-4 fs-7 me-3">Snapshots</span>
          {#if !snapshotsAccess}
            <Lock size="fs-7" />
          {/if}
        </a>
        <hr />
      </li>

      <li class="nav-item mb-1">
        <div>
          <a
            href={routes.currentPosition}
            class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
            class:active={$page.url === routes.currentPosition}
            use:inertia={{ method: "get" }}
          >
            <PiggyBank size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Current Position</span>
            {#if !currentPositionAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              class="collapsed chevron"
              data-bs-toggle="collapse"
              data-bs-target="#current-collapse-mobile"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Current Position menu"
            >
              <ChevronDown />
            </div>
          </a>
        </div>
        <div class="collapse mt-2" id="current-collapse-mobile">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.operationalExpenses}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.operationalExpenses}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Expenses size="fs-4" />
                <span class="ms-4 fs-7">Operational Expenses</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.wages}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.wages}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Wages size="fs-4" />
                <span class="ms-4 fs-7">Wages Direct</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.wagesIndirect}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.wagesIndirect}
                use:inertia={{ method: "get" }}
              >
                <MoneyCheck size="fs-4" />
                <span class="ms-4 fs-7">Wages Indirect</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.kpi}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.kpi}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Kpi size="fs-4" />
                <span class="ms-4 fs-7">KPI Cash/Accrual</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.businessWorth}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url.includes(routes.businessWorth)}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <MagnifyingGlassDollar size="fs-4" />
                <span class="ms-4 me-2 fs-7">Business Worth</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.breakeven}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.breakeven}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Breakeven size="fs-4" />
                <span class="ms-4 fs-7">Breakeven</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.billableHours}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.billableHours}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Hours size="fs-4" />
                <span class="ms-4 fs-7">Billable Hours</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.chargeOut}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.chargeOut}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <ChargeOut size="fs-4" />
                <span class="ms-4 fs-7">Charge Out</span>
              </a>
            </li>
          </ul>
        </div>

        <hr />
      </li>

      <li class="nav-item mb-1">
        <div
          data-bs-toggle="collapse"
          data-bs-target="#scenario-collapse-mobile"
          aria-expanded="false"
          role="button"
          aria-label="Expand/collapse Scenario Testing menu"
        >
          <div class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark">
            <Scenario size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Scenario Testing</span>
            {#if !scenarioTestingAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              class="collapsed chevron ms-1"
              data-bs-toggle="collapse"
              data-bs-target="#scenario-collapse-mobile"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Scenario Testing menu"
            >
              <ChevronDown />
            </div>
          </div>
        </div>
        <div class="collapse mt-2" id="scenario-collapse-mobile">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.chargeOutCalcualtor}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.chargeOutCalcualtor}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <ChargeOut size="fs-4" />
                <span class="ms-4 fs-7">Charge Out</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.breakevenCalculator}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.breakevenCalculator}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Breakeven size="fs-4" />
                <span class="ms-4 fs-7">Breakeven</span>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </li>

      <li class="nav-item mb-1">
        <div
          data-bs-toggle="collapse"
          data-bs-target="#future-collapse-mobile"
          aria-expanded="false"
          role="button"
          aria-label="Expand/collapse Future Planning menu"
        >
          <a
            href={routes.futurePlanning}
            class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
            class:active={$page.url === routes.futurePlanning}
            use:inertia={{ method: "get" }}
          >
            <Future size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Future Planning</span>
            {#if !futurePlanningAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              class="collapsed chevron ms-1"
              data-bs-toggle="collapse"
              data-bs-target="#future-collapse-mobile"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Future Planning menu"
            >
              <ChevronDown />
            </div>
          </a>
        </div>
        <div class="collapse mt-2" id="future-collapse-mobile">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Goals size="fs-4" />
                <span class="ms-4 fs-7">Goals</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Projections size="fs-4" />
                <span class="ms-4 fs-7">Projections</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <Comparison size="fs-4" />
                <span class="ms-4 fs-7">Comparisons</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
                on:click={sideNav.toggle}
              >
                <CashGap size="fs-4" />
                <span class="ms-4 fs-7">Cash Gap</span>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </li>
    </ul>
  </nav>

  <!-- normal nav -->
  <nav
    class="d-none d-lg-flex flex-column flex-shrink-0 p-3 text-bg-dark position-fixed top-0 bottom-0 overflow-x-hidden
    overflow-y-auto transition-500 {$sideNav ? 'w-260' : 'w-69'} "
  >
    <a href="/" class="d-flex align-items-center mb-1 me-md-auto text-white text-decoration-none ms-2">
      <Tools size="fs-4 ms-2" />
      <span class="fs-5 ms-3">Tools4Profit</span>
    </a>
    <hr />
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item mb-2">
        <a
          href="/"
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === "/"}
          use:inertia={{ method: "get" }}
        >
          <Dashboard size="fs-4" />
          <span class="ms-4 fs-7">Dashboard</span>
        </a>
      </li>
      <li class="nav-item mb-2">
        <a
          href={routes.business}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.business}
          use:inertia={{ method: "get" }}
        >
          <Business size="fs-4" />
          <span class="ms-4 fs-7">Business</span>
        </a>
      </li>
      <li class="nav-item mb-1">
        <a
          href={routes.employees}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.employees}
          use:inertia={{ method: "get" }}
        >
          <Employees size="fs-4" />
          <span class="ms-4 fs-7">Employees</span>
        </a>
      </li>
      <li class="nav-item mb-2">
        <a
          href={routes.notes}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url === routes.notes}
          use:inertia={{ method: "get" }}
        >
          <Clipboard size="fs-4" />
          <span class="ms-4 fs-7">Notes</span>
        </a>
        <hr />
      </li>
      <li class="nav-item mb-1">
        <a
          href={routes.snapshots}
          class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
          class:active={$page.url.includes(routes.snapshots)}
          use:inertia={{ method: "get" }}
        >
          <Camera size="fs-4" />
          <span class="ms-4 fs-7 me-3">Snapshots</span>
          {#if !snapshotsAccess}
            <Lock size="fs-7" />
          {/if}
        </a>
        <hr />
      </li>

      <li class="nav-item mb-1">
        <div>
          <a
            href={routes.currentPosition}
            class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
            class:active={$page.url === routes.currentPosition}
            use:inertia={{ method: "get" }}
          >
            <PiggyBank size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Current Position</span>
            {#if !currentPositionAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              id="current-position-arrow"
              class="collapsed chevron"
              data-bs-toggle="collapse"
              data-bs-target="#current-collapse"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Current Position menu"
            >
              <ChevronDown />
            </div>
          </a>
        </div>
        <div class="collapse mt-2" id="current-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.operationalExpenses}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.operationalExpenses}
                use:inertia={{ method: "get" }}
              >
                <Expenses size="fs-4" />
                <span class="ms-4 fs-7">Operational Expenses</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.wages}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.wages}
                use:inertia={{ method: "get" }}
              >
                <Wages size="fs-4" />
                <span class="ms-4 fs-7">Wages Direct</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.wagesIndirect}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.wagesIndirect}
                use:inertia={{ method: "get" }}
              >
                <MoneyCheck size="fs-4" />
                <span class="ms-4 fs-7">Wages Indirect</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.kpi}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.kpi}
                use:inertia={{ method: "get" }}
              >
                <Kpi size="fs-4" />
                <span class="ms-4 fs-7">KPI Cash/Accrual</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.businessWorth}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url.includes(routes.businessWorth)}
                use:inertia={{ method: "get" }}
              >
                <MagnifyingGlassDollar size="fs-4" />
                <span class="ms-4 me-2 fs-7">Business Worth</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.breakeven}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.breakeven}
                use:inertia={{ method: "get" }}
              >
                <Breakeven size="fs-4" />
                <span class="ms-4 fs-7">Breakeven</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.billableHours}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.billableHours}
                use:inertia={{ method: "get" }}
              >
                <Hours size="fs-4" />
                <span class="ms-4 fs-7">Billable Hours</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.chargeOut}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.chargeOut}
                use:inertia={{ method: "get" }}
              >
                <ChargeOut size="fs-4" />
                <span class="ms-4 fs-7">Charge Out</span>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </li>

      <li class="nav-item mb-1">
        <div
          data-bs-toggle="collapse"
          data-bs-target="#scenario-collapse"
          aria-expanded="false"
          role="button"
          aria-label="Expand/collapse Scenario Testing menu"
        >
          <div class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark">
            <Scenario size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Scenario Testing</span>
            {#if !scenarioTestingAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              id="scenario-testing-arrow"
              class="collapsed chevron ms-1"
              data-bs-toggle="collapse"
              data-bs-target="#scenario-collapse"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Scenario Testing menu"
            >
              <ChevronDown />
            </div>
          </div>
        </div>
        <div class="collapse mt-2" id="scenario-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.chargeOutCalculator}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.chargeOutCalculator}
                use:inertia={{ method: "get" }}
              >
                <ChargeOut size="fs-4" />
                <span class="ms-4 fs-7">Charge Out</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.breakevenCalculator}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.breakevenCalculator}
                use:inertia={{ method: "get" }}
              >
                <Breakeven size="fs-4" />
                <span class="ms-4 fs-7">Breakeven</span>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </li>

      <li class="nav-item mb-1">
        <div
          data-bs-toggle="collapse"
          data-bs-target="#future-collapse"
          aria-expanded="false"
          role="button"
          aria-label="Expand/collapse Future Planning menu"
        >
          <a
            href={routes.futurePlanning}
            class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
            class:active={$page.url === routes.futurePlanning}
            use:inertia={{ method: "get" }}
          >
            <Future size="fs-4" />
            <span class="ms-4 fs-7 flex-grow-1">Future Planning</span>
            {#if !futurePlanningAccess}
              <Lock size="fs-7" />
            {/if}
            <div
              id="future-planning-arrow"
              class="collapsed chevron ms-1"
              data-bs-toggle="collapse"
              data-bs-target="#future-collapse"
              aria-expanded="false"
              role="button"
              aria-label="Expand/collapse Future Planning menu"
            >
              <ChevronDown />
            </div>
          </a>
        </div>
        <div class="collapse mt-2" id="future-collapse">
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
              >
                <Goals size="fs-4" />
                <span class="ms-4 fs-7">Goals</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
              >
                <Projections size="fs-4" />
                <span class="ms-4 fs-7">Projections</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
              >
                <Comparison size="fs-4" />
                <span class="ms-4 fs-7">Comparisons</span>
              </a>
            </li>
            <li class="nav-item mb-2">
              <a
                href={routes.futurePlanning}
                class="nav-link text-white d-flex align-items-center t4p-nav-link border border-dark"
                class:active={$page.url === routes.futurePlanning}
                use:inertia={{ method: "get" }}
              >
                <CashGap size="fs-4" />
                <span class="ms-4 fs-7">Cash Gap</span>
              </a>
            </li>
          </ul>
        </div>
        <hr />
      </li>
    </ul>
  </nav>
</aside>

<style>
  .t4p-nav-link {
    transition: 0.5s;
  }

  .t4p-nav-link:hover {
    border: 1px solid var(--lwt-pink) !important;
  }

  .translate-x-0 {
    transform: translateX(0);
  }

  .translate-x-neg-full {
    transform: translateX(-100%);
  }

  .z-index-5 {
    z-index: 5;
  }

  .nav {
    width: 228px;
  }

  .chevron {
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
  }

  .chevron[aria-expanded="false"] {
    transform: rotate(-90deg);
  }
</style>
