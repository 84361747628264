<script>
  import TopNav from "./TopNav.svelte";
  import Sidebar from "./Sidebar.svelte";
  import Footer from "./Footer.svelte";
  import { sideNav } from "../stores/store";
  import FlashMessages from "./FlashMessages.svelte";
  import { page } from "@inertiajs/svelte";
  import setTimezoneCookie from "~/javascript/setTimezoneCookie";

  setTimezoneCookie();
</script>

{#if $page.props.auth}
  <main class="bg-colour">
    <!-- {#if $page.props.auth.user.role === "admin"} -->
    <!-- <Adminbar /> -->
    <!-- {:else} -->
    <Sidebar />
    <!-- {/if} -->
    <div class="transition-500 p-3 min-vh-100 d-flex flex-column {$sideNav ? 'ms-lg-260' : 'ms-lg-69'}">
      <TopNav />
      <FlashMessages />

      <section class="flex-grow-1">
        <slot><p>Oops Something is Missing....</p></slot>
      </section>

      <Footer />
      <div
        class="position-fixed top-0 left-0 bg-dark min-vw-100 min-vh-100 bg-opacity-50 d-lg-none {!$sideNav
          ? ''
          : 'd-none'}"
        id="nav-modal"
        role="button"
        tabindex="0"
        on:click={sideNav.toggle}
        on:keyup={e => {
          if (e.code === "Space") {
            sideNav.toggle;
          }
        }}
      >
        &nbsp;
      </div>
    </div>
  </main>
{:else}
  <div>
    <FlashMessages />
    <slot />
  </div>
{/if}

<style>
  .bg-colour {
    background-color: var(--lwt-bg-color);
  }
</style>
