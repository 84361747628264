<script>
  import { page } from "@inertiajs/svelte";
  import { onMount, afterUpdate } from "svelte";
  import Toast from "bootstrap/js/dist/toast";

  let toastDiv;

  let type = "success";

  $: flash = $page.props.flash;
  $: show = $page.props.has_flash;

  $: msg = "";
  $: hide = true;

  $: if (show) {
    if (flash.alert) {
      type = "danger";
      msg = flash.alert;
      hide = false;
    }

    if (flash.notice) {
      type = "primary";
      msg = flash.notice;
    }

    if (flash.success) {
      type = "success";
      msg = flash.success;
    }

    if (flash.error) {
      type = "danger";
      msg = flash.error;
    }

    if (flash.warning) {
      type = "warning";
      msg = flash.warning;
    }

    if (flash.info) {
      type = "info";
      msg = flash.info;
    }
  }

  function showToast() {
    const toastMsg = new Toast(toastDiv);
    if (show) {
      toastMsg.show();
    }
  }

  onMount(() => {
    showToast();
  });

  afterUpdate(() => {
    showToast();
  });
</script>

<div class="bg-transparent position-fixed top-0 start-50 translate-middle-x mt-5 z-index-5">
  <div
    class="toast align-items-center text-bg-{type} z-index-5 w-auto"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-bs-autohide={hide}
    bind:this={toastDiv}
  >
    <div class="d-flex">
      <div class="toast-body me-3">{msg}</div>
      <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" />
    </div>
  </div>
</div>

<style>
  .z-index-5 {
    z-index: 5;
  }
</style>
